<template>
	<div class="login">
		<h2 class="text-gray-800 font-bold text-lg mb-3 font-nunito">
			Sign In
		</h2>
		<p class="w-full text-sm text-gray-700 mb-10 font-nunito">
			Please login using your e-mail and password to access your account.
		</p>
		<form class="auth-form" @submit.prevent="onFormSubmit">
			<div class="mb-4">
				<label for="email" class="font-nunito font-medium">
					Email address
				</label>
				<input
					v-model="email"
					data-cy="email"
					type="email"
					name="email"
					required
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter your email"
				/>
			</div>
			<div class="mb-4">
				<div class="flex justify-between">
					<label for="password" class="font-nunito font-medium">
						Password
					</label>
					<router-link
						:to="{ name: 'password.forgot' }"
						class="text-sm text-gray-700"
						data-cy="forgot-password-link"
					>
						Forgot your password?
					</router-link>
				</div>
				<input
					v-model="password"
					data-cy="password"
					type="password"
					name="password"
					required
					placeholder="Enter your password"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
				/>
			</div>
			<div class="mb-4">
				<label for="remember">
					<input
						v-model="remember"
						type="checkbox"
						name="remember"
						class="font-nunito font-medium"
					/>
					Remember me
				</label>
			</div>
			<button
				:disabled="busy"
				type="submit"
				class="flex font-roboto uppercase tracking-wide ml-auto bg-blue-500 hover:bg-blue-700 text-white text-sm items-center font-medium py-2 px-3 rounded focus:outline-none focus:shadow-outline"
			>
				<font-awesome-icon
					v-if="busy"
					class="btn-icon fa-spin mr-2"
					:icon="['far', 'circle-notch']"
				/>
				<font-awesome-icon
					v-else
					class="btn-icon mr-2"
					:icon="['far', 'sign-in']"
				/>
				<span class="btn-label">Log In</span>
			</button>
		</form>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Redirect the user when the user becomes authenticated.
		 *
		 * @param {Boolean} authenticated
		 * @return {void}
		 */
		onAuthenticated(authenticated) {
			if (authenticated) {
				this.$router.push({
					name: 'app.calls',
					params: {
						partner: this.$store.getters.partner.id,
					},
				})
			} else {
				if (window.Intercom) {
					window.Intercom('shutdown')
					window.Intercom('boot', {
						api_base:
							process.env.VUE_APP_INTERCOM_API ||
							'https://api-iam.intercom.io',
						app_id: process.env.VUE_APP_INTERCOM_ID || 'z1oijek0',
					})
				}
			}
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				this.busy = true

				const response = await this.login({
					username: this.email,
					password: this.password,
				})

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.busy = false
		},

		...mapActions({
			login: 'auth/login',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Login',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the user's authenticated state.
		 *
		 * @return {void}
		 */
		authenticated: {
			handler: 'onAuthenticated',
			immediate: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			email: '',
			password: '',
			remember: false,
		}
	},
}
</script>
